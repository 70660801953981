import React from "react";
import { Box } from "rebass/styled-components";
import Container from "../components/UI/Container";
import Page from "../templates/Page";
import { useStaticQuery, graphql } from "gatsby";
import Markdown from "../components/UI/Markdown";
import HeaderPage from "../components/UI/HeaderPage";

export default () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { title: { eq: "legals" } }) {
          frontmatter {
            name
            url
            address
            publication
            url
            mail
            phone
            confidential
          }
        }
      }
    `
  );
  const legals = markdownRemark.frontmatter || {};
  for (const id in legals) {
    if (id !== "confidential") {
      var re = new RegExp("{" + id + "}", "g");
      legals.confidential = legals.confidential.replace(re, legals[id]);
    }
  }
  return (
    <Page title="Politique de confidentialité">
      <HeaderPage title="Politique de confidentialité" />
      <Box py={10}>
        <Container>
          <Markdown
            sx={{
              h1: {
                color: "text"
              },
              a: {
                color: "blue",
                textDecoration: "underline"
              }
            }}
          >
            {legals.confidential}
          </Markdown>
        </Container>
      </Box>
    </Page>
  );
};
